* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: #242424;
  background-color: #ffffff;
}

a {
  color: #242424;
}